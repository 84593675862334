body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 50px;
  background-color: #a9a9a9;
}


a {
  color: #2f3030;
}

.table {
  font-size: 75%;
}

.container {
  background-color: whitesmoke;
  padding: 2em;
  border-radius: 15px;
}
.btn {
  margin-top: 6px;
  margin-bottom: 12px;
}

.input {
  margin-top: 6px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
